import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import tłumaczeń
import pl from './locales/pl.json';
import en from './locales/en.json';
import cn from './locales/cn.json';

// Sprawdzenie środowiska
const isBrowser = typeof window !== 'undefined';

i18n
  .use(LanguageDetector) // Automatyczna detekcja języka
  .use(initReactI18next) // Integracja z React
  .init({
    resources: {
      pl: { translation: pl },
      en: { translation: en },
      cn: { translation: cn }
    },
    fallbackLng: 'pl', // Domyślny język
    debug: process.env.NODE_ENV === 'development', // Debugowanie w trybie dev
    interpolation: {
      escapeValue: false // Brak potrzeby ucieczki znaków w React
    },
    detection: isBrowser
      ? {
          order: ['localStorage', 'querystring', 'navigator'], // Kolejność detekcji języka
          caches: ['localStorage'], // Przechowywanie wybranego języka
        }
      : {}, // Brak detekcji w środowisku serwerowym
    react: {
      useSuspense: true, // Obsługa Suspense w React
    },
  });

// Debugowanie w trybie deweloperskim
if (process.env.NODE_ENV === 'development') {
  i18n.on('initialized', () => {
    console.log('i18n initialized', i18n.language);
    console.log('Available resources:', Object.keys(i18n.options.resources));
  });

  i18n.on('languageChanged', (lng) => {
    console.log('Language changed to:', lng);
  });
}

export default i18n;